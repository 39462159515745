/**
 * @flow
 * @relayHash c3a3c9d3b42c0f8699a731fc7ed4e297
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateExplainerInput = {
  id?: ?string,
  title?: ?string,
  description?: ?string,
  url?: ?string,
  language?: ?string,
  tags?: ?$ReadOnlyArray<?string>,
  channel?: ?string,
  trashed?: ?boolean,
  clientMutationId?: ?string,
};
export type ExplainerFormUpdateExplainerMutationVariables = {|
  input: UpdateExplainerInput
|};
export type ExplainerFormUpdateExplainerMutationResponse = {|
  +updateExplainer: ?{|
    +explainer: ?{|
      +id: string,
      +dbid: ?number,
      +title: ?string,
      +description: ?string,
      +url: ?string,
      +language: ?string,
      +tags: ?$ReadOnlyArray<?string>,
      +user: ?{|
        +dbid: ?number,
        +name: ?string,
      |},
      +team: ?{|
        +dbid: ?number,
        +slug: string,
      |},
    |}
  |}
|};
export type ExplainerFormUpdateExplainerMutation = {|
  variables: ExplainerFormUpdateExplainerMutationVariables,
  response: ExplainerFormUpdateExplainerMutationResponse,
|};
*/


/*
mutation ExplainerFormUpdateExplainerMutation(
  $input: UpdateExplainerInput!
) {
  updateExplainer(input: $input) {
    explainer {
      id
      dbid
      title
      description
      url
      language
      tags
      user {
        dbid
        name
        id
      }
      team {
        dbid
        slug
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateExplainerInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateExplainerInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "language",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tags",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ExplainerFormUpdateExplainerMutation",
  "id": null,
  "text": "mutation ExplainerFormUpdateExplainerMutation(\n  $input: UpdateExplainerInput!\n) {\n  updateExplainer(input: $input) {\n    explainer {\n      id\n      dbid\n      title\n      description\n      url\n      language\n      tags\n      user {\n        dbid\n        name\n        id\n      }\n      team {\n        dbid\n        slug\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ExplainerFormUpdateExplainerMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateExplainer",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateExplainerPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "explainer",
            "storageKey": null,
            "args": null,
            "concreteType": "Explainer",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  v3,
                  v9
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "team",
                "storageKey": null,
                "args": null,
                "concreteType": "PublicTeam",
                "plural": false,
                "selections": [
                  v3,
                  v10
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ExplainerFormUpdateExplainerMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateExplainer",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateExplainerPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "explainer",
            "storageKey": null,
            "args": null,
            "concreteType": "Explainer",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  v3,
                  v9,
                  v2
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "team",
                "storageKey": null,
                "args": null,
                "concreteType": "PublicTeam",
                "plural": false,
                "selections": [
                  v3,
                  v10,
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a948d491794e3fad9dab5f3fa340ce46';
module.exports = node;
