/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type FeedTopBar_feed$ref: FragmentReference;
export type FeedTopBar_feed = {|
  +dbid: ?number,
  +published: ?boolean,
  +permissions: ?string,
  +feed_teams: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +saved_search_id: ?number,
        +team: ?{|
          +dbid: ?number,
          +avatar: ?string,
          +name: string,
          +slug: string,
        |},
      |}
    |}>
  |},
  +current_feed_team: ?{|
    +saved_search: ?{|
      +dbid: ?number,
      +title: ?string,
    |}
  |},
  +saved_search: ?{|
    +dbid: ?number,
    +title: ?string,
  |},
  +$refType: FeedTopBar_feed$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "saved_search",
  "storageKey": null,
  "args": null,
  "concreteType": "SavedSearch",
  "plural": false,
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Fragment",
  "name": "FeedTopBar_feed",
  "type": "Feed",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "published",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "permissions",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "feed_teams",
      "storageKey": "feed_teams(first:1000)",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1000,
          "type": "Int"
        }
      ],
      "concreteType": "FeedTeamConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "FeedTeamEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "FeedTeam",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "saved_search_id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "team",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "PublicTeam",
                  "plural": false,
                  "selections": [
                    v0,
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "avatar",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "name",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "slug",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "current_feed_team",
      "storageKey": null,
      "args": null,
      "concreteType": "FeedTeam",
      "plural": false,
      "selections": [
        v1
      ]
    },
    v1
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '40bb0b9ece907035423c8cdf50899c1c';
module.exports = node;
