/**
 * @flow
 * @relayHash 5731fe4145a776e92c83205c8545b4c2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateFactCheckInput = {
  id?: ?string,
  url?: ?string,
  language?: ?string,
  tags?: ?$ReadOnlyArray<?string>,
  rating?: ?string,
  channel?: ?string,
  title?: ?string,
  summary?: ?string,
  trashed?: ?boolean,
  clientMutationId?: ?string,
};
export type ClaimFactCheckFormUpdateFactCheckMutationVariables = {|
  input: UpdateFactCheckInput
|};
export type ClaimFactCheckFormUpdateFactCheckMutationResponse = {|
  +updateFactCheck: ?{|
    +fact_check: ?{|
      +id: string,
      +updated_at: ?string,
      +title: ?string,
      +summary: ?string,
      +url: ?string,
      +language: ?string,
      +rating: ?string,
      +tags: ?$ReadOnlyArray<?string>,
      +user: ?{|
        +name: ?string
      |},
      +claim_description: ?{|
        +description: ?string,
        +context: ?string,
        +project_media: ?{|
          +title: ?string,
          +title_field: ?string,
          +custom_title: ?string,
          +status: ?string,
          +last_status: ?string,
          +last_status_obj: ?{|
            +id: string,
            +locked: ?boolean,
          |},
        |},
      |},
    |}
  |}
|};
export type ClaimFactCheckFormUpdateFactCheckMutation = {|
  variables: ClaimFactCheckFormUpdateFactCheckMutationVariables,
  response: ClaimFactCheckFormUpdateFactCheckMutationResponse,
|};
*/


/*
mutation ClaimFactCheckFormUpdateFactCheckMutation(
  $input: UpdateFactCheckInput!
) {
  updateFactCheck(input: $input) {
    fact_check {
      id
      updated_at
      title
      summary
      url
      language
      rating
      tags
      user {
        name
        id
      }
      claim_description {
        description
        context
        project_media {
          title
          title_field
          custom_title
          status
          last_status
          last_status_obj {
            id
            locked
          }
          id
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateFactCheckInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateFactCheckInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updated_at",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "summary",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "language",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rating",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "tags",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "context",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "title_field",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "custom_title",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "last_status",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "last_status_obj",
  "storageKey": null,
  "args": null,
  "concreteType": "Dynamic",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "locked",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ClaimFactCheckFormUpdateFactCheckMutation",
  "id": null,
  "text": "mutation ClaimFactCheckFormUpdateFactCheckMutation(\n  $input: UpdateFactCheckInput!\n) {\n  updateFactCheck(input: $input) {\n    fact_check {\n      id\n      updated_at\n      title\n      summary\n      url\n      language\n      rating\n      tags\n      user {\n        name\n        id\n      }\n      claim_description {\n        description\n        context\n        project_media {\n          title\n          title_field\n          custom_title\n          status\n          last_status\n          last_status_obj {\n            id\n            locked\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ClaimFactCheckFormUpdateFactCheckMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateFactCheck",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateFactCheckPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "fact_check",
            "storageKey": null,
            "args": null,
            "concreteType": "FactCheck",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              v9,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  v10
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "claim_description",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimDescription",
                "plural": false,
                "selections": [
                  v11,
                  v12,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "project_media",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProjectMedia",
                    "plural": false,
                    "selections": [
                      v4,
                      v13,
                      v14,
                      v15,
                      v16,
                      v17
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ClaimFactCheckFormUpdateFactCheckMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateFactCheck",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateFactCheckPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "fact_check",
            "storageKey": null,
            "args": null,
            "concreteType": "FactCheck",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              v9,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "user",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  v10,
                  v2
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "claim_description",
                "storageKey": null,
                "args": null,
                "concreteType": "ClaimDescription",
                "plural": false,
                "selections": [
                  v11,
                  v12,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "project_media",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProjectMedia",
                    "plural": false,
                    "selections": [
                      v4,
                      v13,
                      v14,
                      v15,
                      v16,
                      v17,
                      v2
                    ]
                  },
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '86e3b0496ed73616f2ad4e6d13e44848';
module.exports = node;
