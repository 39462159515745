/**
 * @flow
 * @relayHash 374bb6905721d5614a66b3510c754741
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FeedHeader_feed$ref = any;
type FeedHeader_feedTeam$ref = any;
type FeedTopBar_feed$ref = any;
type FeedTopBar_team$ref = any;
export type FeedQueryVariables = {|
  slug: string,
  feedId: number,
|};
export type FeedQueryResponse = {|
  +team: ?{|
    +name: string,
    +feed: ?{|
      +dbid: ?number,
      +team_id: ?number,
      +name: ?string,
      +published: ?boolean,
      +filters: ?any,
      +saved_search_id: ?number,
      +data_points: ?$ReadOnlyArray<?number>,
      +teams: {|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +dbid: ?number,
            +name: string,
          |}
        |}>
      |},
      +current_feed_team: ?{|
        +team_id: ?number,
        +saved_search_id: ?number,
        +filters: ?any,
        +$fragmentRefs: FeedHeader_feedTeam$ref,
      |},
      +$fragmentRefs: FeedTopBar_feed$ref & FeedHeader_feed$ref,
    |},
    +$fragmentRefs: FeedTopBar_team$ref,
  |}
|};
export type FeedQuery = {|
  variables: FeedQueryVariables,
  response: FeedQueryResponse,
|};
*/


/*
query FeedQuery(
  $slug: String!
  $feedId: Int!
) {
  team(slug: $slug) {
    name
    feed(dbid: $feedId) {
      dbid
      team_id
      name
      published
      filters
      saved_search_id
      data_points
      teams(first: 1000) {
        edges {
          node {
            dbid
            name
            id
          }
        }
      }
      current_feed_team {
        team_id
        saved_search_id
        filters
        ...FeedHeader_feedTeam
        id
      }
      ...FeedTopBar_feed
      ...FeedHeader_feed
      id
    }
    ...FeedTopBar_team
    id
  }
}

fragment FeedHeader_feedTeam on FeedTeam {
  team_id
  permissions
}

fragment FeedTopBar_feed on Feed {
  dbid
  published
  permissions
  feed_teams(first: 1000) {
    edges {
      node {
        saved_search_id
        team {
          dbid
          avatar
          name
          slug
          id
        }
        id
      }
    }
  }
  current_feed_team {
    saved_search {
      dbid
      title
      id
    }
    id
  }
  saved_search {
    dbid
    title
    id
  }
}

fragment FeedHeader_feed on Feed {
  dbid
  team_id
  licenses
  permissions
}

fragment FeedTopBar_team on Team {
  slug
  avatar
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "feedId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "dbid",
    "variableName": "feedId",
    "type": "Int!"
  }
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "team_id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "published",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "filters",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "saved_search_id",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "data_points",
  "args": null,
  "storageKey": null
},
v10 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000,
    "type": "Int"
  }
],
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "permissions",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "saved_search",
  "storageKey": null,
  "args": null,
  "concreteType": "SavedSearch",
  "plural": false,
  "selections": [
    v4,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "title",
      "args": null,
      "storageKey": null
    },
    v11
  ]
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "avatar",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "FeedQuery",
  "id": null,
  "text": "query FeedQuery(\n  $slug: String!\n  $feedId: Int!\n) {\n  team(slug: $slug) {\n    name\n    feed(dbid: $feedId) {\n      dbid\n      team_id\n      name\n      published\n      filters\n      saved_search_id\n      data_points\n      teams(first: 1000) {\n        edges {\n          node {\n            dbid\n            name\n            id\n          }\n        }\n      }\n      current_feed_team {\n        team_id\n        saved_search_id\n        filters\n        ...FeedHeader_feedTeam\n        id\n      }\n      ...FeedTopBar_feed\n      ...FeedHeader_feed\n      id\n    }\n    ...FeedTopBar_team\n    id\n  }\n}\n\nfragment FeedHeader_feedTeam on FeedTeam {\n  team_id\n  permissions\n}\n\nfragment FeedTopBar_feed on Feed {\n  dbid\n  published\n  permissions\n  feed_teams(first: 1000) {\n    edges {\n      node {\n        saved_search_id\n        team {\n          dbid\n          avatar\n          name\n          slug\n          id\n        }\n        id\n      }\n    }\n  }\n  current_feed_team {\n    saved_search {\n      dbid\n      title\n      id\n    }\n    id\n  }\n  saved_search {\n    dbid\n    title\n    id\n  }\n}\n\nfragment FeedHeader_feed on Feed {\n  dbid\n  team_id\n  licenses\n  permissions\n}\n\nfragment FeedTopBar_team on Team {\n  slug\n  avatar\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "FeedQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed",
            "storageKey": null,
            "args": v3,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              v4,
              v5,
              v2,
              v6,
              v7,
              v8,
              v9,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "teams",
                "storageKey": "teams(first:1000)",
                "args": v10,
                "concreteType": "PublicTeamConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PublicTeamEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PublicTeam",
                        "plural": false,
                        "selections": [
                          v4,
                          v2
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "current_feed_team",
                "storageKey": null,
                "args": null,
                "concreteType": "FeedTeam",
                "plural": false,
                "selections": [
                  v5,
                  v8,
                  v7,
                  {
                    "kind": "FragmentSpread",
                    "name": "FeedHeader_feedTeam",
                    "args": null
                  }
                ]
              },
              {
                "kind": "FragmentSpread",
                "name": "FeedTopBar_feed",
                "args": null
              },
              {
                "kind": "FragmentSpread",
                "name": "FeedHeader_feed",
                "args": null
              }
            ]
          },
          {
            "kind": "FragmentSpread",
            "name": "FeedTopBar_team",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FeedQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "feed",
            "storageKey": null,
            "args": v3,
            "concreteType": "Feed",
            "plural": false,
            "selections": [
              v4,
              v5,
              v2,
              v6,
              v7,
              v8,
              v9,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "teams",
                "storageKey": "teams(first:1000)",
                "args": v10,
                "concreteType": "PublicTeamConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "PublicTeamEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "PublicTeam",
                        "plural": false,
                        "selections": [
                          v4,
                          v2,
                          v11
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "current_feed_team",
                "storageKey": null,
                "args": null,
                "concreteType": "FeedTeam",
                "plural": false,
                "selections": [
                  v5,
                  v8,
                  v7,
                  v12,
                  v11,
                  v13
                ]
              },
              v12,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "feed_teams",
                "storageKey": "feed_teams(first:1000)",
                "args": v10,
                "concreteType": "FeedTeamConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "FeedTeamEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "FeedTeam",
                        "plural": false,
                        "selections": [
                          v8,
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "team",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "PublicTeam",
                            "plural": false,
                            "selections": [
                              v4,
                              v14,
                              v2,
                              v15,
                              v11
                            ]
                          },
                          v11
                        ]
                      }
                    ]
                  }
                ]
              },
              v13,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "licenses",
                "args": null,
                "storageKey": null
              },
              v11
            ]
          },
          v15,
          v14,
          v11
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '26799030872b2ba78fb8cd11d1e6f2a1';
module.exports = node;
