/**
 * @flow
 * @relayHash b126f025128887e3719ec57c148aeec6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateExplainerInput = {
  title?: ?string,
  description?: ?string,
  url?: ?string,
  language?: ?string,
  tags?: ?$ReadOnlyArray<?string>,
  channel?: ?string,
  clientMutationId?: ?string,
};
export type ExplainerFormCreateExplainerMutationVariables = {|
  input: CreateExplainerInput
|};
export type ExplainerFormCreateExplainerMutationResponse = {|
  +createExplainer: ?{|
    +team: ?{|
      +explainersCount: ?number,
      +totalArticlesCount: ?number,
    |},
    +explainer: ?{|
      +dbid: ?number
    |},
  |}
|};
export type ExplainerFormCreateExplainerMutation = {|
  variables: ExplainerFormCreateExplainerMutationVariables,
  response: ExplainerFormCreateExplainerMutationResponse,
|};
*/


/*
mutation ExplainerFormCreateExplainerMutation(
  $input: CreateExplainerInput!
) {
  createExplainer(input: $input) {
    team {
      explainersCount: articles_count(article_type: "explainer")
      totalArticlesCount: articles_count
      id
    }
    explainer {
      dbid
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateExplainerInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateExplainerInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": "explainersCount",
  "name": "articles_count",
  "args": [
    {
      "kind": "Literal",
      "name": "article_type",
      "value": "explainer",
      "type": "String"
    }
  ],
  "storageKey": "articles_count(article_type:\"explainer\")"
},
v3 = {
  "kind": "ScalarField",
  "alias": "totalArticlesCount",
  "name": "articles_count",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dbid",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ExplainerFormCreateExplainerMutation",
  "id": null,
  "text": "mutation ExplainerFormCreateExplainerMutation(\n  $input: CreateExplainerInput!\n) {\n  createExplainer(input: $input) {\n    team {\n      explainersCount: articles_count(article_type: \"explainer\")\n      totalArticlesCount: articles_count\n      id\n    }\n    explainer {\n      dbid\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ExplainerFormCreateExplainerMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createExplainer",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateExplainerPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v2,
              v3
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "explainer",
            "storageKey": null,
            "args": null,
            "concreteType": "Explainer",
            "plural": false,
            "selections": [
              v4
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ExplainerFormCreateExplainerMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createExplainer",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateExplainerPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v2,
              v3,
              v5
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "explainer",
            "storageKey": null,
            "args": null,
            "concreteType": "Explainer",
            "plural": false,
            "selections": [
              v4,
              v5
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f71174306337124d20f17d8ec146c2a';
module.exports = node;
