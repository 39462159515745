/**
 * @flow
 * @relayHash bcecf343474a8990afabe91ac955a377
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateExplainerInput = {
  id?: ?string,
  title?: ?string,
  description?: ?string,
  url?: ?string,
  language?: ?string,
  tags?: ?$ReadOnlyArray<?string>,
  channel?: ?string,
  trashed?: ?boolean,
  clientMutationId?: ?string,
};
export type ArticleTrashExplainerMutationVariables = {|
  input: UpdateExplainerInput
|};
export type ArticleTrashExplainerMutationResponse = {|
  +updateExplainer: ?{|
    +explainer: ?{|
      +id: string,
      +trashed: ?boolean,
    |},
    +team: ?{|
      +explainerCount: ?number,
      +trashCount: ?number,
    |},
  |}
|};
export type ArticleTrashExplainerMutation = {|
  variables: ArticleTrashExplainerMutationVariables,
  response: ArticleTrashExplainerMutationResponse,
|};
*/


/*
mutation ArticleTrashExplainerMutation(
  $input: UpdateExplainerInput!
) {
  updateExplainer(input: $input) {
    explainer {
      id
      trashed
    }
    team {
      explainerCount: articles_count(article_type: "explainer")
      trashCount: articles_count(trashed: true)
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateExplainerInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateExplainerInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "explainer",
  "storageKey": null,
  "args": null,
  "concreteType": "Explainer",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "trashed",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": "explainerCount",
  "name": "articles_count",
  "args": [
    {
      "kind": "Literal",
      "name": "article_type",
      "value": "explainer",
      "type": "String"
    }
  ],
  "storageKey": "articles_count(article_type:\"explainer\")"
},
v5 = {
  "kind": "ScalarField",
  "alias": "trashCount",
  "name": "articles_count",
  "args": [
    {
      "kind": "Literal",
      "name": "trashed",
      "value": true,
      "type": "Boolean"
    }
  ],
  "storageKey": "articles_count(trashed:true)"
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ArticleTrashExplainerMutation",
  "id": null,
  "text": "mutation ArticleTrashExplainerMutation(\n  $input: UpdateExplainerInput!\n) {\n  updateExplainer(input: $input) {\n    explainer {\n      id\n      trashed\n    }\n    team {\n      explainerCount: articles_count(article_type: \"explainer\")\n      trashCount: articles_count(trashed: true)\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ArticleTrashExplainerMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateExplainer",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateExplainerPayload",
        "plural": false,
        "selections": [
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v4,
              v5
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ArticleTrashExplainerMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateExplainer",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateExplainerPayload",
        "plural": false,
        "selections": [
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v4,
              v5,
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f8fc64e68a31f961b7e11f2c2261c363';
module.exports = node;
