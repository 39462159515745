/**
 * @flow
 * @relayHash 88899f7ab691bce7b7f85e68f7e41d1c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateFactCheckInput = {
  id?: ?string,
  url?: ?string,
  language?: ?string,
  tags?: ?$ReadOnlyArray<?string>,
  rating?: ?string,
  channel?: ?string,
  title?: ?string,
  summary?: ?string,
  trashed?: ?boolean,
  clientMutationId?: ?string,
};
export type ArticleTrashFactCheckMutationVariables = {|
  input: UpdateFactCheckInput
|};
export type ArticleTrashFactCheckMutationResponse = {|
  +updateFactCheck: ?{|
    +fact_check: ?{|
      +id: string,
      +trashed: ?boolean,
    |},
    +team: ?{|
      +factChecksCount: ?number,
      +publishedCount: ?number,
      +importedCount: ?number,
      +trashCount: ?number,
    |},
  |}
|};
export type ArticleTrashFactCheckMutation = {|
  variables: ArticleTrashFactCheckMutationVariables,
  response: ArticleTrashFactCheckMutationResponse,
|};
*/


/*
mutation ArticleTrashFactCheckMutation(
  $input: UpdateFactCheckInput!
) {
  updateFactCheck(input: $input) {
    fact_check {
      id
      trashed
    }
    team {
      factChecksCount: articles_count(article_type: "fact-check")
      publishedCount: articles_count(article_type: "fact-check", report_status: "published")
      importedCount: articles_count(article_type: "fact-check", imported: true)
      trashCount: articles_count(trashed: true)
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateFactCheckInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateFactCheckInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "fact_check",
  "storageKey": null,
  "args": null,
  "concreteType": "FactCheck",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "trashed",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "Literal",
  "name": "article_type",
  "value": "fact-check",
  "type": "String"
},
v5 = {
  "kind": "ScalarField",
  "alias": "factChecksCount",
  "name": "articles_count",
  "args": [
    v4
  ],
  "storageKey": "articles_count(article_type:\"fact-check\")"
},
v6 = {
  "kind": "ScalarField",
  "alias": "publishedCount",
  "name": "articles_count",
  "args": [
    v4,
    {
      "kind": "Literal",
      "name": "report_status",
      "value": "published",
      "type": "[String]"
    }
  ],
  "storageKey": "articles_count(article_type:\"fact-check\",report_status:\"published\")"
},
v7 = {
  "kind": "ScalarField",
  "alias": "importedCount",
  "name": "articles_count",
  "args": [
    v4,
    {
      "kind": "Literal",
      "name": "imported",
      "value": true,
      "type": "Boolean"
    }
  ],
  "storageKey": "articles_count(article_type:\"fact-check\",imported:true)"
},
v8 = {
  "kind": "ScalarField",
  "alias": "trashCount",
  "name": "articles_count",
  "args": [
    {
      "kind": "Literal",
      "name": "trashed",
      "value": true,
      "type": "Boolean"
    }
  ],
  "storageKey": "articles_count(trashed:true)"
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ArticleTrashFactCheckMutation",
  "id": null,
  "text": "mutation ArticleTrashFactCheckMutation(\n  $input: UpdateFactCheckInput!\n) {\n  updateFactCheck(input: $input) {\n    fact_check {\n      id\n      trashed\n    }\n    team {\n      factChecksCount: articles_count(article_type: \"fact-check\")\n      publishedCount: articles_count(article_type: \"fact-check\", report_status: \"published\")\n      importedCount: articles_count(article_type: \"fact-check\", imported: true)\n      trashCount: articles_count(trashed: true)\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ArticleTrashFactCheckMutation",
    "type": "MutationType",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateFactCheck",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateFactCheckPayload",
        "plural": false,
        "selections": [
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v5,
              v6,
              v7,
              v8
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ArticleTrashFactCheckMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateFactCheck",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateFactCheckPayload",
        "plural": false,
        "selections": [
          v3,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "team",
            "storageKey": null,
            "args": null,
            "concreteType": "Team",
            "plural": false,
            "selections": [
              v5,
              v6,
              v7,
              v8,
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8f66712b83611b04b00f6741d4f254e0';
module.exports = node;
